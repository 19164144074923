<template>
  <Header title="Event At Rio" />

  <section class="inner_banner_slider_section top_margin">
    <div class="inner_banner_slider_row">
      <div class="inner_banner_slider_btn_col">
        <div class="inner_banner_slider_btn_block"></div>
      </div>
      <div class="inner_banner_slider_col">
        <div class="inner_banner_slider_block">
          <div class="gallery inner_banner_slider">
            <Carousel
              ref="villas"
              :items-to-show="1"
              :wrap-around="true"
              :autoplay="2000"
            >
              <Slide key="1">
                <img
                  src="../../src/assets/images/event_img/event-slider-5.jpg"
                />
              </Slide>
              <Slide key="2">
                <img
                  src="../../src/assets/images/event_img/event-slider-6.jpg"
                />
              </Slide>
              <Slide key="3">
                <img
                  src="../../src/assets/images/event_img/event-slider-1.jpg"
                />
              </Slide>
              <Slide key="4">
                <img
                  src="../../src/assets/images/event_img/event-slider-2.jpg"
                />
              </Slide>
            </Carousel>
          </div>
        </div>
      </div>
      <div class="inner_banner_col">
        <div class="inner_banner_block">
          <p class="body_heading tc">Events @ Rio</p>
        </div>
      </div>
    </div>
  </section>
  <section class="b_booking_section top_margin">
    <div class="b_booking_row row_padding main_container">
      <div class="site_button_div extra_btn" @click="clickToDown()">
        <span class="font_20 site_button success">Book Now</span>
      </div>
      <div class="b_booking_col">
        <div class="b_booking_block" v-if="list.data && list.data.length">
          <div
            class="b_booking_box b_event_box"
            v-for="(item, index) in list.data"
            :key="index" 
          >
            <div class="b_booking_lt">
              <div class="b_booking_inr_lt">
                <div class="b_booking_img_slider">
                  <div class="b_booking_slider_for">
                    <img class="zoomIcon" src="../assets/images/zoom.png" />
                    <Carousel
                      :items-to-show="1"
                      :wrap-around="false"
                      v-model="currentSlide"
                      :transition="500"
                    >
                      <Slide v-for="image in item.event_image" :key="image">
                        <div class="b_booking_for_imag">
                          <a
                            class="b_booking_for_img"
                            :href="image.image"
                            data-lightbox="models"
                          >
                            <img :src="image.image" />
                          </a>
                        </div>
                      </Slide>
                    </Carousel>
                  </div>
                  <div class="b_booking_slider_nav">
                    <Carousel
                      :items-to-show="5"
                      :wrap-around="true"
                      v-model="currentSlide"
                      ref="carousel"
                      :autoplay="2000"
                      :transition="500"
                    >
                      <Slide
                        v-for="(image, index2) in item.event_image"
                        :key="index2"
                      >
                        <div class="b_booking_nav_imag">
                          <div
                            class="b_booking_nav_img"
                            @click="slideTo(index2 + 1 - 1)"
                          >
                            <img :src="image.image" />
                          </div>
                        </div>
                      </Slide>
                    </Carousel>
                  </div>
                </div>
              </div>
            </div>
            <div class="b_booking_rt">
              <div class="b_booking_inr_rt">
                <div class="b_booking_rt_box_1 event_book_box">
                  <div class="b_booking_rt_titie">
                    <p class="body_heading">{{ item.name }}</p>
                  </div>
                  <div class="b_booking_text" v-show="false">
                    <p class="data_sub_heading"></p>
                  </div>
                  <div class="b_booking_vill">
                    <div class="vila_title_info font_24">
                      <span>{{ item.event_date }}</span>
                    </div>
                  </div>
                  <div
                    class="remaining_slots badge rounded-pill"
                    v-bind:class="{
                      'badge-light-success': item.is_active,
                      'badge-light': !item.is_active,
                    }" v-if="item.is_active"
                  >
                    <span>{{ item.available_slot_note }}</span>
                  </div>
                  <div class="package_content_block event_block_con">
                    <div class="package_content_outter">
                      <div class="package_content_top_block" v-if="item.is_active">
                        <div class="package_content_inner">
                          <div class="price_box_out">
                            <div class="price_box_inn">
                              <div class="price_main_box">
                                <div
                                  class="price_main_white_box event_price_box"
                                >
                                  <div class="box_title_text">
                                    Price For Adults<br />
                                    <div class="sub">(12+ Years)</div>
                                  </div>
                                  <div class="package_price_box">
                                    <p class="data_sub_heading">
                                      ₹{{
                                        item.adult_price
                                          .toString()
                                          .replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )
                                      }}.00
                                    </p>
                                    <p class="data_sub_heading taxes">
                                      (18% GST INC.)
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div class="price_main_box">
                                <div
                                  class="price_main_white_box event_price_box"
                                >
                                  <div class="box_title_text">
                                    Price For Children<br />
                                    <div class="sub">(5 - 12 Years)</div>
                                  </div>
                                  <div class="package_price_box">
                                    <p class="data_sub_heading">
                                      ₹{{
                                        item.child_price
                                          .toString()
                                          .replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )
                                      }}.00
                                    </p>
                                    <p class="data_sub_heading taxes">
                                      (18% GST INC.)
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="package_content_bottom_block">
                        <div class="package_content_inner">
                          <div class="package_point_outter">
                            <div class="package_faqs_inner event_pc_box">
                              <img
                                src="../../src/assets/images/event_img/nwicon1.png"
                                class="package_inner_img"
                              />
                              <p class="font_16 package_inner_title tc">
                                Live DJ
                              </p>
                            </div>
                            <div class="package_faqs_inner event_pc_box">
                              <img
                                src="../../src/assets/images/event_img/nwicon2.png"
                                class="package_inner_img"
                              />
                              <p class="font_16 package_inner_title tc">
                                Unlimited Foods
                              </p>
                            </div>
                            <div class="package_faqs_inner event_pc_box">
                              <img
                                src="../../src/assets/images/event_img/hficon2.png"
                                class="package_inner_img"
                              />
                              <p class="font_16 package_inner_title tc">
                                Foam Dance
                              </p>
                            </div>
                            <div class="package_faqs_inner event_pc_box">
                              <img
                                src="../../src/assets/images/event_img/hficon5.png"
                                class="package_inner_img"
                              />
                              <p class="font_16 package_inner_title tc">
                                Organic Color
                              </p>
                            </div>
                            <!-- <div class="package_faqs_inner event_pc_box">
                              <img
                                src="../../src/assets/images/event_img/nwicon3.png"
                                class="package_inner_img"
                              />
                              <p class="font_16 package_inner_title tc">
                                Dance Party
                              </p>
                            </div>
                            <div class="package_faqs_inner event_pc_box">
                              <img
                                src="../../src/assets/images/event_img/nwicon5.png"
                                class="package_inner_img"
                              />
                              <p class="font_16 package_inner_title tc">
                                Night Stay*
                              </p>
                            </div> -->
                            <div
                              class="package_faqs_inner event_pc_box"
                              v-if="item.from_date != '2024-03-25'"
                            >
                              <img
                                src="../../src/assets/images/event_img/nwicon6.png"
                                class="package_inner_img"
                              />
                              <p class="font_16 package_inner_title tc">
                                Fire Work Show
                              </p>
                            </div>
                            <div class="package_faqs_inner event_pc_box">
                              <img
                                src="../../src/assets/images/event_img/nwicon8.png"
                                class="package_inner_img"
                              />
                              <p class="font_16 package_inner_title tc">
                                Mudbath
                              </p>
                            </div>
                            <div class="package_faqs_inner event_pc_box">
                              <img
                                src="../../src/assets/images/event_img/nwicon7.png"
                                class="package_inner_img"
                              />
                              <p class="font_16 package_inner_title tc">
                                Many More Activies
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="b_booking_view">
                    <!-- <a href="#" class="b_booking_a_view"> -->
                    <!-- <router-link to="/photo" class="b_booking_a_view">
                                            <span>View All</span>
                                        </router-link> -->
                    <!-- </a> -->
                  </div>
                  <div class="btn_block btn_center_block event_btn">
                    <div class="btn_border block_cta">
                      <div v-if="item.is_active">
                        <router-link
                          class="site_button success"
                          :to="{
                            name: 'event-booking',
                            params: {
                              event_id: item.id,
                            },
                          }"
                          v-if="item.available_slot"
                        >
                          <div class="site_button_div">
                            <span class="font_20">Book Now</span>
                          </div>
                        </router-link>
                        <a v-else class="site_button disabled" disabled>
                          <div class="site_button_div">
                            <span class="font_20">Sold Out!</span>
                          </div>
                        </a>
                      </div>

                      <router-link class="site_button" to="/contact-us" v-if="item.is_active">
                        <div class="site_button_div">
                          <span class="font_20">Send Inquirey</span>
                        </div>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- ---------- FILLER SECTION ---------- -->
  <Download />

  <!-- BODY SECTION END -->
  <Footer />
</template>

<script>
import Footer from "../components/Footer.vue";
import Header from "../components/Header.vue";
import GP_btn_01Png from "../assets/images/gp-img.png";
import AS_btn_01Png from "../assets/images/as-img.png";
import axios from "axios";

import { Carousel, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";

import Download from "@/pages/Download.vue";

export default {
  name: "EventAtRio",
  components: { Header, Footer, Carousel, Slide, Download },
  data() {
    return {
      GP_btn_01Png: GP_btn_01Png,
      AS_btn_01Png: AS_btn_01Png,
      list: [],
      currentSlide: 0,
    };
  },
  beforeMount() {
    setTimeout(() => {
      const recaptchaScript = document.createElement("script");
      recaptchaScript.setAttribute("src", "./js/script.js");
      document.head.appendChild(recaptchaScript);
    }, 30);
  },
  mounted() {
    this.getEvents();
    window.scrollTo({ top: 0, behavior: "smooth" });
  },
  methods: {
    clickToDown() {
      if (window.screen.width < 768) {
        window.scrollTo({ top: 790, behavior: "smooth" });
      }
    },
    slideTo(val) {
      this.currentSlide = val;
    },
    async getEvents(page = 1) {
      await axios
        .get(process.env.VUE_APP_API_CUS_URL + "/get-events?page=" + page + "&type=web")
        .then((response) => {
          this.list = response.data.data;
        });
    },
  },
};
</script>

<style scoped>
.event_banner {
  background-image: url("../../src/assets/images/event_img/event-slider-1.jpg");
  background-repeat: no-repeat;
}

.b_event_box {
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 0px 27px 0px rgba(0, 0, 0, 0.1);
}

.font_24 {
  font-size: 24px;
  font-weight: 500;
}

.event_price_box {
  border-radius: 10px;
  background: #e1ebd3;
}

.event_block_con {
  padding: 0 !important;
  width: 100%;
}

.price_main_white_box.event_price_box {
  text-align: center;
}

.event_book_box {
  width: 100% !important;
}

.price_main_white_box.event_price_box .package_price_box {
  justify-content: center;
}

.b_booking_box.b_event_box .b_booking_lt {
  width: 50%;
}

.b_booking_box.b_event_box .b_booking_rt {
  width: 50%;
}

.b_booking_box.b_event_box .b_booking_for_img img {
  width: 100%;
  height: 450px;
  object-fit: cover;
  border-radius: 20px;
}

.b_booking_box.b_event_box .b_booking_nav_img img {
  width: 100%;
  height: 130px;
  object-fit: cover;
  border-radius: 10px;
}

.package_content_block.event_block_con .package_point_outter {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: left;
}

.package_faqs_inner.event_pc_box {
  width: 14%;
  margin: 0;
}

.btn_block.btn_center_block.event_btn .btn_border.block_cta {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.long_l_leaf:before,
.long_r_leaf:after {
  z-index: -99 !important;
}

section.b_booking_section.top_margin {
  margin-top: 0 !important;
}

.sliderImages {
  position: relative;
}

.zoomIcon {
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 2;
  filter: invert(1);
  cursor: pointer;
}

.extra_btn .site_button {
  width: 100%;
  margin: auto 80px;
  display: none;
  margin-bottom: 29px !important;
  padding: 20px;
  line-height: unset;
  justify-content: center;
  align-items: center;
}

@media all and (min-width: 320px) and (max-width: 767px) {
  .b_booking_box.b_event_box .b_booking_for_img img {
    height: 300px;
  }

  .package_price_box .data_sub_heading {
    padding-bottom: 0;
    line-height: 24px;
  }

  .sub {
    line-height: 26px;
  }

  .package_faqs_inner.event_pc_box {
    width: 47%;
    margin: auto 0;
    padding-bottom: 0;
    display: flex;
    align-items: center;
    padding: 0 !important;
  }

  .package_faqs_inner.event_pc_box img {
    margin: 0;
    width: 35px;
  }

  .package_content_block.event_block_con .price_box_inn {
    padding: 0px 0 !important;
  }

  .b_booking_box.b_event_box .b_booking_nav_img img {
    width: 100%;
    height: 70px;
  }

  .b_event_box .vila_title_info.font_24 {
    text-align: center;
  }

  .b_booking_box.b_event_box .b_booking_lt {
    width: 100%;
  }

  .b_booking_box.b_event_box .b_booking_rt {
    width: 100%;
  }

  .package_faqs_inner.event_pc_box p {
    padding-left: 10px;
    text-align: left;
  }

  .package_content_block.event_block_con .package_point_outter {
    margin-top: 20px;
  }

  .b_event_box a.b_booking_a_view {
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .b_event_box .b_booking_text p.data_sub_heading {
    font-size: 15px;
  }

  .btn_block.btn_center_block.event_btn .btn_border.block_cta {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .remaining_slots {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: 10px;
  }

  .extra_btn .site_button {
    display: flex;
  }
}

@media all and (min-width: 481px) and (max-width: 767px) {
}

@media all and (min-width: 768px) and (max-width: 980px) {
  .b_booking_box.b_event_box .b_booking_for_img img {
    height: 400px;
  }

  .extra_btn .site_button {
    display: flex;
  }

  .package_faqs_inner.event_pc_box {
    width: 31%;
    margin: 0;
    padding-bottom: 0;
    display: flex;
    align-items: center;
  }

  .package_content_block.event_block_con .price_box_inn {
    padding: 0px 0 !important;
  }

  .b_booking_box.b_event_box .b_booking_nav_img img {
    width: 100%;
    height: 100px;
  }

  .b_booking_box.b_event_box .b_booking_lt {
    width: 100%;
  }

  .b_booking_box.b_event_box .b_booking_rt {
    width: 100%;
  }

  .package_faqs_inner.event_pc_box p {
    padding-left: 10px;
  }

  .btn_block.btn_center_block.event_btn .btn_border.block_cta {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
}

@media all and (min-width: 981px) and (max-width: 1024px) {
  .b_booking_box.b_event_box .b_booking_for_img img {
    height: 400px;
  }

  .package_faqs_inner.event_pc_box {
    width: 22%;
    margin: 0;
    padding-bottom: 0;
  }

  .package_content_block.event_block_con .price_box_inn {
    padding: 0px 0 !important;
  }

  .b_booking_box.b_event_box .b_booking_nav_img img {
    width: 100%;
    height: 100px;
  }
}

@media all and (min-width: 1025px) and (max-width: 1140px) {
  .b_booking_box.b_event_box .b_booking_for_img img {
    height: 400px;
  }

  .package_faqs_inner.event_pc_box {
    width: 22%;
    margin: 0;
    padding-bottom: 0;
  }

  .package_content_block.event_block_con .price_box_inn {
    padding: 0px 0 !important;
  }

  .b_booking_box.b_event_box .b_booking_nav_img img {
    width: 100%;
    height: 100px;
  }
}

@media all and (min-width: 1141px) and (max-width: 1280px) {
  .b_booking_box.b_event_box .b_booking_for_img img {
    height: 400px;
  }

  .package_faqs_inner.event_pc_box {
    width: 17%;
    margin: 0;
    padding-bottom: 0;
  }

  .package_content_block.event_block_con .price_box_inn {
    padding: 0px 0 !important;
  }
}

@media all and (min-width: 1281px) and (max-width: 1440px) {
  .b_booking_box.b_event_box .b_booking_for_img img {
    height: 400px;
  }

  .package_faqs_inner.event_pc_box {
    width: 17%;
    margin: 0;
  }
}

@media all and (min-width: 1441px) and (max-width: 1680px) {
}

@media all and (min-width: 1681px) and (max-width: 1880px) {
}

.sliderImages {
  position: relative;
}

.zoomIcon {
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 2;
  filter: invert(1);
  cursor: pointer;
}

.lb-data .lb-close {
  width: 508px;
  position: absolute;
  top: 21px;
  right: 0;
  left: 0;
  margin: auto;
}

.lightbox {
  left: 0px;
  padding-top: 50px;
}

div#lightbox img {
  height: 450px !important;
  object-fit: cover !important;
  max-width: 550px !important;
  display: block;
  margin: 0 auto;
}

div#lightbox {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column-reverse;
}

.event_btn .site_button a {
  color: #fff !important;
  transition: 1s !important;
}

.event_btn .site_button:hover a {
  color: #527422 !important;
}

.b_booking_slider_for {
  position: relative;
}

.b_booking_for_imag {
  width: 100%;
}

.long_r_leaf:after {
  right: unset !important;
}

.site_button.disabled {
  opacity: 0.5;
}

.site_button.disabled:hover {
  background: transparent;
  border: 1px solid #000;
  color: #000 !important;
  transition: 1s !important;
  opacity: 0.5;
}

.site_button:hover {
  background: transparent;
  border: 1px solid #000;
  color: #000 !important;
  transition: 1s !important;
}

.site_button.success {
  background: #7da741;
  border: 1px solid #7da74175;
  color: #fff !important;
  transition: 1s !important;
}

.block_cta .site_button:nth-child(1) {
  margin-right: 10px;
}

.remaining_slots {
  padding: 5px 10px;
}

.remaining_slots.badge-light-success {
  background-color: #7da741;
  border-color: #7da741;
}

.package_price_box,
.btn_border.block_cta {
  display: unset;
}

.taxes {
  font-size: 16px;
}

.box_title_text {
  margin-bottom: 15px !important;
}

.sub {
  font-size: 16px;
  display: inline-block;
}
</style>
